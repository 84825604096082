@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import "prose";

// Login
body {
  padding-top: 58px;
}

.login-hero-unit-wrapper {
  height: calc(100vh - 158px);
}

.login-hero-unit {
  max-width: 60rem;
}

.login-hero-unit-heading {
  color: $primary;
  font-size: 3.5rem;
  background: linear-gradient(90deg, $primary-light, $primary);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Search
.col-checkbox {
  flex: 0 0 1.5rem;
}

// Player
.col-playlist {
  max-height: calc(100vh - 182px);
  overflow-y: scroll;
}

.playlist.active {
  background: $gray-200;

  .playlist-title::before {
    content: "\F4F4";
    font-family: bootstrap-icons;
    line-height: 1;
  }
}

// Utilities
.ffs-palt {
  font-feature-settings: "palt";
  letter-spacing: 0.02em;
}

.form-check-input {
  border-color: var(--bs-gray-600);
}

.smaller {
  font-size: 0.75rem;
}

.hover-animation {
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.aspect-ratio-16x9 {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.font_red {
  color: #ff0000;
}
.prose {
  h1 {
    @extend .fs-2;
    @extend .mb-4;
  }
  h2 {
    @extend .fs-4;
    @extend .my-4;
  }
  h3 {
    @extend .fs-5;
    @extend .mt-4;
    @extend .mb-3;
  }
  h4 {
    @extend .fs-6;
    @extend .mt-3;
    @extend .mb-3;
  }
  p {
    line-height: 1.7;
  }
}

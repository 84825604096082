$primary: #2b4570;
$primary-light: #1e9cd7;
$success: #32a383;
$warning: #f15a24;

$min-contrast-ratio: 3;

$bootstrap-icons-font-dir: "../fonts";
$font-family-sans-serif: Helvetica Neue, Arial, Hiragino Sans, Hiragino Kaku Gothic ProN, BIZ UDPGothic, Meiryo, sans-serif;
$headings-font-weight: 600;

$line-height-base: 1.6;

$input-placeholder-color: var(--bs-gray-500);
